import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApplicantListResponse, IApplicationStatus, IVisaApplicantParams } from '../../models/applicant.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicantService {
  private readonly apiBaseUrl = environment.apiBaseUrl;
  private readonly http = inject(HttpClient);

  getVisaApplicantForms(queryParams: IVisaApplicantParams): Observable<IApplicantListResponse> {
    let params = new HttpParams();

    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key as keyof IVisaApplicantParams]) {
        const value = queryParams[key as keyof IVisaApplicantParams];
        if (value !== undefined) {
          params = params.set(key, value.toString());
        }
      }
    });

    return this.http.get<IApplicantListResponse>(`${this.apiBaseUrl}form/response/tenant`, { params });
  }

  approveStatus(formResponseId: string, status: number) {
    return this.http.put(`${this.apiBaseUrl}form/response/${formResponseId}/${status}`, {});
  }

  getApprovalStatus() {
    return this.http.get<IApplicationStatus[]>(`${this.apiBaseUrl}form/response-status`);
  }

  exportVisaApplicantForm(formResponseId: string) {
    return this.http.get(`${this.apiBaseUrl}form/${formResponseId}/export`, { responseType: 'blob' });
  }
}
